import restApiConnection from '@/services/api/providers/Connection'
import deviceAlertInfo from '@/types/deviceAlertInfo'
import deviceAlertsResponse from '@/types/responses/deviceAlertsResponse'
import getDeviceAlertsResponse from '@/types/responses/getDeviceAlertsResponse'
import RestApiBase from '@/services/models/RestApiBase'
import UserSession from '@/services/models/UserSession'
import store from '@/services/store'
import { AxiosResponse } from 'axios'

export default class DeviceAlert extends RestApiBase {
  public static async find(
    delivery_plan_id: string,
    startDateTime: number | null = null,
    endDateTime: number | null = null
  ): Promise<deviceAlertsResponse> {
    let key = 'DeviceAlertDeliveryPlanId' + delivery_plan_id
    if (startDateTime) key += 'startDateTime:' + startDateTime
    if (endDateTime) key += 'endDateTime:' + endDateTime
    return this.sync(key, this.selectByDeliveryPlanId, {
      delivery_plan_id,
      startDateTime,
      endDateTime,
    })
  }

  public static selectByDeliveryPlanId = async (args: {
    delivery_plan_id: string
    startDateTime: number
    endDateTime: number
  }): Promise<deviceAlertsResponse> => {
    let merged: deviceAlertInfo[] = []
    let maxPages = 1
    // limitPerPage * limitPages = 300 records
    const limitPerPage = 10
    const limitPages = 30
    for (let i = 0; i < maxPages; i++) {
      const page = i + 1
      const config = {
        cache: true,
        params: {
          delivery_plan_id: args.delivery_plan_id,
          page: page,
          limit: limitPerPage,
          user_session_id: UserSession.getStoredUserId(),
          ...(args.startDateTime && { start_date_time: args.startDateTime }),
          ...(args.endDateTime && { end_date_time: args.endDateTime }),
        },
      }
      const res: AxiosResponse<getDeviceAlertsResponse> | undefined =
        await restApiConnection
          .get('/device_alerts', config)
          .catch(async (error) => {
            console.error('API Call Failed on DeviceAlert. Error:', error)
            throw error
          })

      if (res && res.data) {
        const alerts: getDeviceAlertsResponse = res?.data
        maxPages = Math.min(alerts?.total_pages ?? 1, limitPages)

        const deviceAlerts = alerts?.device_alerts ?? []
        if (deviceAlerts.length === 0) {
          break
        }

        merged = merged.concat(deviceAlerts)
      }
    }
    return new Promise((resolve) => {
      resolve(<deviceAlertsResponse>{ data: merged })
    })
  }
}
