import restApiConnection from '@/services/api/providers/Connection'
import userSessionResponse from '@/types/responses/userSessionResponse'
import RestApiBase from '@/services/models/RestApiBase'
import router from '@/routes'
import store from '@/services/store'

export default class UserSession extends RestApiBase {
  public static async get(): Promise<userSessionResponse | undefined> {
    return this.sync('GetUserSession', this.getOwn)
  }

  public static getOwn = async (): Promise<userSessionResponse | undefined> => {
    let ret: userSessionResponse | undefined = undefined
    if (!restApiConnection.defaults?.headers?.common?.Authorization)
      return Promise.reject()

    await restApiConnection
      .get(process.env.VUE_APP_USER_SESSION_API_URL || '', { cache: true })
      .then((data) => {
        ret = data
      })
      .catch(async (error) => {
        console.error('API Call Failed on UserSession. Error:', error)
        throw error
      })
    return new Promise((resolve) => {
      resolve(ret)
    })
  }

  public static getStoredUserId(): string | null {
    const user_session = store.getters.plainUserSession
    return user_session?.user_session_id || null
  }
}
