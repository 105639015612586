import restApiConnection from '@/services/api/providers/Connection'
import RestApiBase from '@/services/models/RestApiBase'
import myOperationOffice from '@/types/myOperationOffice'
import UserSession from '@/services/models/UserSession'
import { AxiosResponse } from 'axios'

export default class MyOperationOffice extends RestApiBase {
  public static async get(): Promise<
    AxiosResponse<myOperationOffice | undefined>
  > {
    return this.sync('GetMyOperationOffice', this.getOwn)
  }

  public static getOwn = async (
    retryCount: number = 0
  ): Promise<AxiosResponse<myOperationOffice>> => {
    const config = {
      cache: true,
      params: {
        user_session_id: UserSession.getStoredUserId(),
      },
    }

    return await restApiConnection
      .get('/my_operation_office', config)
      .catch(async (error) => {
        console.error('API Call Failed on driver. Error:', error)
        throw error
      })
  }
}
