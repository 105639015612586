import { createStore } from 'vuex'
import Api from '@/services/api/ApiServiceFabrick'
import errorResponse from '@/types/responses/errorResponse'
import { useAuth } from '@/services/user/Auth0UserProvider'
import axios from '@/services/api/providers/Connection'
import UserSession from '@/services/models/UserSession'
import AppSyncClient from '@/services/query/providers/Connection'

const store = createStore({
  state: {
    auth0Token: sessionStorage.getItem('auth0Token')
      ? JSON.parse(sessionStorage.getItem('auth0Token')!)
      : null,
    userSession: sessionStorage.getItem('userSession')
      ? JSON.parse(sessionStorage.getItem('userSession')!)
      : null,
    myOperationOffice: sessionStorage.getItem('myOperationOffice')
      ? JSON.parse(sessionStorage.getItem('myOperationOffice')!)
      : null,
    vehiclesResult: null,
    isRefreshing: false,
    refreshSubscribers: [] as Array<(newToken: string) => void>,
  },
  mutations: {
    setUserSession(state, sessionData) {
      state.userSession = sessionData
      sessionStorage.setItem('userSession', JSON.stringify(sessionData))
    },
    clearUserSession(state) {
      state.userSession = null
      sessionStorage.removeItem('userSession')
    },
    setAuth0Token(state, auth0Data) {
      state.auth0Token = auth0Data
      sessionStorage.setItem('auth0Token', JSON.stringify(auth0Data))
    },
    clearAuth0Token(state) {
      state.auth0Token = null
      sessionStorage.removeItem('auth0Token')
    },
    setAuth0Refreshing(state, isRefreshing) {
      state.isRefreshing = isRefreshing
    },
    addAuth0Subscriber(state, callback) {
      state.refreshSubscribers.push(callback)
    },
    addRefreshSubscriber(state, subscriber: (newToken: string) => void) {
      state.refreshSubscribers.push(subscriber)
    },
    runSubscribers(state, newToken: string) {
      state.refreshSubscribers.forEach((callback) => callback(newToken))
      state.refreshSubscribers = []
    },
    setMyOperationOffice(state, myOperationOffice) {
      state.myOperationOffice = myOperationOffice
      sessionStorage.setItem(
        'myOperationOffice',
        JSON.stringify(myOperationOffice)
      )
    },
    setVehiclesResult(state, vehiclesResult) {
      state.vehiclesResult = vehiclesResult
    },
  },
  actions: {
    fetchVehiclesResult({ commit }) {
      Api()
        .getVehicles((vehiclesResult: any) => {
          commit('setVehiclesResult', vehiclesResult)
        })
        .catch((error: errorResponse) => {
          console.log(error)
          throw error
        })
    },
    async refreshToken({ state, commit }) {
      if (state.isRefreshing) {
        // 更新中なら Promise を返す
        return new Promise<string>((resolve) => {
          commit('addRefreshSubscriber', resolve)
        })
      }

      const auth0 = useAuth()
      if (auth0.isAuthenticated.value) {
        // tokenを取得
        auth0.getTokenSilently().then((token) => {
          // Bearer Tokenを設定
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
          // Vuex保存
          store.commit('setAuth0Token', token)

          // UserSession Tokenを設定
          UserSession.get().then((response) => {
            if (response?.data) {
              // Vuex保存
              store.commit('setUserSession', response?.data)
              store.commit('runSubscribers', response?.data)
              store.commit('setAuth0Refreshing', false)
              AppSyncClient.token = token
            }
          })
        })
      }
    },
    addRefreshSubscriber({ commit }, callback) {
      commit('addAuth0Subscriber', callback)
    },
  },
  getters: {
    plainUserSession: (state) => JSON.parse(JSON.stringify(state.userSession)),
    plainAuth0Token: (state) => JSON.parse(JSON.stringify(state.auth0Token)),
    plainMyOperationOffice: (state) =>
      JSON.parse(JSON.stringify(state.myOperationOffice)),
  },
})

export default store
