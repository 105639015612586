import { createApp, ComponentPublicInstance } from 'vue'
// import { createApp } from 'vue'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import App from './App.vue'
// import Rollbar from 'rollbar'
import router from '@/routes'
import '@/main.css'
import i18n from '@/lang/i18n'
import '@/config/validate'
import { createHead } from '@vueuse/head'
import Notifications from '@kyvg/vue3-notification'
import Amplify from 'aws-amplify'
import aws_exports from './aws-exports'

Amplify.configure({
  ...aws_exports,
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: process.env.VUE_APP_GRAPHQL_API_KEY,
  aws_appsync_graphqlEndpoint: process.env.VUE_APP_GRAPHQL_API_END_POINT,
  aws_appsync_region: process.env.VUE_APP_GRAPHQL_REGION,
})
const app = createApp(App)
const head = createHead()

const env = process.env.VUE_APP_ENVIRONMENT || 'local'
const rollbar = undefined
// const rollbar =
//   env != 'local'
//     ? new Rollbar({
//         accessToken: process.env.VUE_APP_ROLLBAR_ACCESS_TOKEN || '',
//         captureUncaught: false,
//         captureUnhandledRejections: false,
//         environment: env,
//       })
//     : undefined

app.config.globalProperties.$rollbar = rollbar

app.config.errorHandler = (
  err: unknown,
  instance: ComponentPublicInstance | null,
  info: string
) => {
  // if (env != 'local') {
  //   rollbar?.error(err as string)
  // }
}

app
  .use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
      v: 3.58,
    },
  })
  .use(router)
  .use(i18n)
  .use(head)
  .use(Notifications)
  .mount('#app')

// app.config.globalProperties.$rollbar = rollbar
