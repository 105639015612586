import restApiConnection from '@/services/api/providers/Connection'
import spotsResponse from '@/types/responses/spotsResponse'
import RestApiBase from '@/services/models/RestApiBase'
import UserSession from '@/services/models/UserSession'
import store from '@/services/store'
import { AxiosResponse } from 'axios'

export default class Spot extends RestApiBase {
  public static async all(): Promise<spotsResponse> {
    return this.sync('GetSpots', this.selectAll)
  }

  public static selectAll = async (): Promise<spotsResponse> => {
    const config = {
      cache: true,
      params: {
        user_session_id: UserSession.getStoredUserId(),
      },
    }

    const res: AxiosResponse<spotsResponse> | undefined =
      await restApiConnection.get('/spots', config).catch(async (error) => {
        console.error('API Call Failed on spot. Error:', error)
        throw error
      })

    return res.data
  }
}
