import restApiConnection from '@/services/api/providers/Connection'
import driversResponse from '@/types/responses/driversResponse'
import RestApiBase from '@/services/models/RestApiBase'
import UserSession from '@/services/models/UserSession'
import store from '@/services/store'

export default class Driver extends RestApiBase {
  public static async all(
    forceUpdate: boolean = false
  ): Promise<driversResponse> {
    return this.sync('GetDrivers', () => {
      return this.selectAll(!forceUpdate)
    })
  }

  public static selectAll = async (
    cache: boolean = true,
    retryCount: number = 0
  ): Promise<driversResponse> => {
    if (retryCount > 3) {
      throw new Error('Maximum retry attempts reached')
    }

    const config = {
      cache: cache,
      params: {
        user_session_id: UserSession.getStoredUserId(),
      },
    }

    return await restApiConnection
      .get('/drivers', config)
      .catch(async (error) => {
        console.error('API Call Failed on driver. Error:', error)
        throw error
      })
  }
}
