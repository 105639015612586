import restApiConnection from '@/services/api/providers/Connection'
import RestApiBase from '@/services/models/RestApiBase'
import OperationOfficeType from '@/types/operationOffice'
import UserSession from '@/services/models/UserSession'
import { AxiosResponse } from 'axios'

export default class SelfOffice extends RestApiBase {
  public static async get(): Promise<AxiosResponse<OperationOfficeType[]>> {
    return this.sync('GetOperationOffices', this.getOwn)
  }

  public static getOwn = async (
    retryCount: number = 0
  ): Promise<AxiosResponse<OperationOfficeType[]> | undefined> => {
    const config = {
      cache: true,
      params: {
        user_session_id: UserSession.getStoredUserId(),
      },
    }

    return await restApiConnection
      .get('/operation_offices', config)
      .catch(async (error) => {
        console.error('API Call Failed on OperationOffice. Error:', error)
        throw error
      })
  }
}
