import axios, { AxiosRequestConfig } from 'axios'
import { AxiosAdapter } from 'axios'
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions'
import store from '@/services/store'
import UserSession from '@/services/models/UserSession'

const conn = axios.create({
  baseURL: process.env.VUE_APP_REST_API_BASE_URL,
  adapter: throttleAdapterEnhancer(
    cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
      enabledByDefault: false,
    })
  ),
})

const isUserSessionRequest = (config: AxiosRequestConfig) => {
  return config.url == process.env.VUE_APP_USER_SESSION_API_URL
}

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

const setConfigParams = (config: AxiosRequestConfig) => {
  if (!store.state.auth0Token) {
    return null
  }
  // user_session リクエストには user_session_id は不要
  if (!store.state.userSession && !isUserSessionRequest(config)) {
    return null
  }

  config.params = {
    ...(config.params || {}),
    user_session_id: UserSession.getStoredUserId(),
  }
  return config
}

conn.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    for (let i = 0; i < 3; i++) {
      const ret = setConfigParams(config)
      if (ret) {
        return ret
      }
      await delay(2000)
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

conn.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      console.error('Unauthorized or Forbidden. Reloading the page...')
      window.location.reload() // Reload page
    }

    return Promise.reject(error)
  }
)

export default conn
